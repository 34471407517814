exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-elternberatung-js": () => import("./../../../src/pages/angebot/elternberatung.js" /* webpackChunkName: "component---src-pages-angebot-elternberatung-js" */),
  "component---src-pages-angebot-klassenstunden-js": () => import("./../../../src/pages/angebot/klassenstunden.js" /* webpackChunkName: "component---src-pages-angebot-klassenstunden-js" */),
  "component---src-pages-angebot-lerntherapie-js": () => import("./../../../src/pages/angebot/lerntherapie.js" /* webpackChunkName: "component---src-pages-angebot-lerntherapie-js" */),
  "component---src-pages-angebot-vortraege-js": () => import("./../../../src/pages/angebot/vortraege.js" /* webpackChunkName: "component---src-pages-angebot-vortraege-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-orig-js": () => import("./../../../src/pages/index.orig.js" /* webpackChunkName: "component---src-pages-index-orig-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lerntherapie-eidechsli-js": () => import("./../../../src/pages/lerntherapie-eidechsli.js" /* webpackChunkName: "component---src-pages-lerntherapie-eidechsli-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../../../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

